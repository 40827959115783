.filepond--wrapper {
  height: 100% !important;
}

.filepond--root {
  height: 100% !important;
  font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.filepond--root:hover {
  cursor: pointer;
}

.filepond--panel-root {
  background-color: #ffffff;
  min-height: 100% !important;
}

.filepond--drop-label {
  flex: 1 !important;
  min-height: 100% !important;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.filepond--drop-label label:hover {
  cursor: pointer !important;
}
