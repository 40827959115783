.form-container {
  height: 100vh;
  background-size: cover;
  display: grid;
  align-items: center;
  justify-content: center;
  background: #7ae1bf;
}

.ant-form-item-label {
  padding-bottom: 0 !important;
}
