#content {
  height: 100%;
}

#spin-container {
  height: 100%;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
