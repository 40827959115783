#toggle-sidebar {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.progress-rotated {
  transform: rotate(90deg);
  transform-origin: left;
  padding-bottom: 20px;
}
