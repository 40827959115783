@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url(./app/assets/fonts/Barlow-Regular.ttf) format('truetype'),
  url(./app/assets/fonts/Barlow-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
  font-family: 'Barlow', 'Times New Roman', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}