.login-form-item {
  margin-bottom: 8px !important;
}

#start-free-trial-button {
  background: #f5cabf;
  border-color: #f5cabf;
  color: #111111;
}

#reset-password-link {
  padding: 0;
  float: right;
}
