#layout-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  padding-left: 0;
}

.non-selectable-menu-item {
  color: #ffffff !important;
  background: #221c35 !important;
}

.non-selectable-menu-item:hover {
  background: #221c35 !important;
  cursor: default !important;
}
